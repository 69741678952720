import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const Home = React.lazy(() => import('../../pages/Home'));

const App = () => {
  return (
    <React.Suspense>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
